// Here you can add other styles

body {
    background-color: $white;
    font-family: "museo-sans", sans-serif;
}

a {
    font-weight: 900;
    color: $black;
}

a:hover {
    color: $ewt-red;
    text-decoration: none;
}

:focus {
    outline: none!important;
}

::selection {
    background: $ewt-red; /* WebKit/Blink Browsers */
    color: $white;
}

::-moz-selection {
    background: $ewt-red; /* Gecko Browsers */
    color: $white;
}

input, select, textarea {
    border: 1px solid #ddd;
    padding: 5px;
    background-color: #fff;
}

textarea {
    resize:none;
}

.container {
    width: 95vw;
    max-width: 1100px;
}

.separator {
    width: 100%;
    height: 1px;
}

// @media (min-width: 820px){
//     .container {
//         max-width: 800px;
//     }
// }

// @media (min-width: 620px){
//     .container {
//         max-width: 600px;
//     }
// }

.back-to-website-bar {
    width: 100%;
    background-color: #642216;
    padding: 5px;
}

.back-to-website-bar a {
    color: $white;
    font-weight: 300;
}

.header-banner {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    padding: 30px 0px;
}

.header-banner img {
    width: 80%;
    max-width: 474px;
}

.header-banner-links {
    display: grid;
    grid-template-columns: 1fr;
}

.header-banner-links a {
    margin: 0px;
}

.quote-main-form {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
    margin-bottom: 20px;
}

.quote-main-form-tabs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;
    grid-column-gap: 10px;
}

.quote-main-form-tabs p {
    margin: 0px;
    width: 100%;
    background: #ddd;
    text-align: center;
    padding: 5px;
    font-size: calc(13px + (16 - 13) * ((100vw - 300px) / (1600 - 300)));
}

.active-tab {
    color: $ewt-red;
    font-weight: 900;
    background: $white !important;
}

.quote-main-form-fields-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.quote-main-form-fields-form p {
    margin: 0px;
}

.quote-main-form-fields-input {
    width: 100%;
}

.input-validation-error {
    border: 2px solid #C60000
}

.quote-main-form-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.quote-main-form-buttons button {
    min-width: 100px;
    padding: 10px;
    color: white;
    background-color: $ewt-red;
    border: none;
    border-radius: 5px;
    font-size: 14pt;
}

.quote-main-form-buttons button:disabled {
    background-color: #666;
    cursor: not-allowed;
}

.timber-lists {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.timber-list {
    color: #666;
}

.timber-list-details {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
    margin-top: 10px;
}

.timber-list-heading {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
}

.timber-list-heading h5 {
    margin-bottom: 0px;
    font-size: 14pt;
}

.timber-list-name-input {
    border: none;
    width: 30%;
    min-width: 150px;
    padding: 5px;
    color: $ewt-red;
    border: 1px solid #fff;
}

.timber-list-name-input:hover {
    border: 1px solid #ddd;
}

.timber-list-heading .timber-list-icon {
    font-size: 24pt;
}

.timber-list-section, .project-section {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 5px;
}

.toggle-timber-list-icon {
    transition: transform 0.5s;
}

.toggle-timber-list-icon-collapsed {
    transform: rotate(90deg);
}

.timber-list-sub-heading, .project-sub-heading {
    font-size: 12pt;
}

.timber-list-sub-heading svg{
    margin-left: 5px
}

.project-sub-heading {
    color: #666;
}

.timber-list-icon {
    color: $ewt-red;
    font-size: 14pt;
    cursor: pointer;
}

.timber-list-add-list {
    font-size: 14pt;
    color: $ewt-red;
    text-align: center;
}

.timber-list-add-list button {
    width: 100%;
    max-width: 300px;
    text-align: center;
    border-radius: 5px;
    background-color: #642216;
    border: 3px solid #642216;
    color: #fff;
    cursor: pointer;
    white-space: nowrap;
}

.timber-list-add-dimension {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2px;
}

.timber-list-add-dimension button {
    text-align: center;
    border-radius: 5px;
    background-color: #642216;
    border: 3px solid #642216;
    color: #fff;
    cursor: pointer;
    white-space: nowrap;
    padding: 1px 4px;
}

.timber-list-add-dimension button svg {
    color: white;
    font-size: 11pt;
    position: relative;
    top: -1px;
}

.timber-list-add-list svg {
    color: #fff;
    top: -2px;
    position: relative;
}

.timber-list-add-icon {
    transform: rotate(45deg);
}

.timber-list-question-options {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
}

.timber-list-question-option {
    width: 100%;
    padding: 2px;
    text-align: center;
    border-radius: 5px;
    background-color: $ewt-red;
    border: 3px solid $ewt-red;
    color: $white;
    cursor: pointer;
    white-space: nowrap;
}

.question-option-selected {
    position: relative;
    background-color: $white;
    color: black;
    transition: background-color 200ms linear;
}

.question-deselect-icon {
    position: absolute;
    right: 3px;
    top: 3px;
}

.timber-list-cutting-list {
    display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr auto;
    align-items: center;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    width: 100%;
}

.timber-list-cutting-list-header {
    margin: 0px;
    width: 100%;
    text-align: center;
    font-size: calc(11px + (14 - 11) * ((100vw - 300px) / (1600 - 300)));
}

.timber-list-cutting-list-header {
    width: 100%;
    margin: 0px;
}

.timber-list-cutting-list-input {
    width: 100%;
}

.timber-list-cutting-list-profile-list-container {
    position: relative;
    grid-column: 1 / span 4;
}

.timber-list-cutting-list-profile-list-container .dropdown .dropdown-menu{
    width: 100%;
    max-height: 40vh;
    overflow-y: auto;
}

.timber-list-cutting-list-profile-list-button {
    width: 100%;
    height: 31px;
    border: 1px solid #ddd;
    background-color: white;
}

.timber-list-cutting-list-profile-list {
    position: absolute;
    padding: 0px;
    margin: 0px;
    background: white;
    z-index: 99;
    border: 1px solid #ddd;
    border-top: none;
    width: 100%;
}

.timber-list-cutting-list-profile-list-item {
    padding: 10px;
    margin: 0px;
    text-align: center;
}

.timber-list-cutting-list-profile-list-item:hover {
    background: #ddd;
}

.timber-list-cutting-list-profile-list-item img {
    width: auto;
    max-width: 100%;
}

.timber-list-cutting-list-profile-list-item p {
    margin: 0px;
}

.timber-list-help-splash {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
}

.timber-list-help-container {
    margin: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: left;
    font-size: 200%;
    border: 2px solid #666;
    background-color: #ddd;
    padding: 20px;
    width: 80vw;
    min-width: 300px;
    max-width: 800px;
    max-height: 97vh;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 30;
}

// .timber-list-help-container p {
//     font-size: 14pt;
// }

// .project-section-options label{
//     margin-bottom: 0px;
//     margin-right: 30px;
// }

// .project-section-options-radio {
//     transform: scale(2);
// }

.md-radio {
    margin-top: 10px;

    &.md-radio-inline {
        display: inline-block;
    }

    &.md-radio-error {
        label {
            &:before {
                border: 2px solid $md-radio-border-error-color;
            }
        }
    }

    input[type="radio"] {
        display: none;
        &:checked + label:before {
            border-color: $md-radio-checked-color;
            animation: ripple 0.2s linear forwards;
        }
        &:checked + label:after {
            transform: scale(1);
        }
    }

    label {
        display: inline-block;
        min-height: $md-radio-size;
        position: relative;
        padding: 0 ($md-radio-size + 10px);
        padding-top: 3px;
        margin-bottom: 0;
        cursor: pointer;
        vertical-align: bottom;
        font-size: 12pt;
        &:before, &:after {
            position: absolute;
            content: '';
            border-radius: 50%;
            transition: all .3s ease;
            transition-property: transform, border-color;
        }
        &:before {
            left: 0;
            top: 0;
            width: $md-radio-size;
            height: $md-radio-size;
            border: 2px solid $md-radio-border-color;
        }
        &:after {
            top: $md-radio-size / 2 - $md-radio-checked-size / 2;
            left: $md-radio-size / 2 - $md-radio-checked-size / 2;
            width:$md-radio-checked-size;
            height:$md-radio-checked-size;
            transform: scale(0);
            background:$md-radio-checked-color;
        }
    }
}

.md-checkbox {
    position: relative;
    margin: $md-checkbox-margin;
    text-align: left;

    &.md-checkbox-inline {
      display: inline-block;
    }

    &.md-checkbox-error {
        label {
            &:before {
                border: $md-checkbox-border-width solid $md-radio-border-error-color;
            }
        }
    }

    label {
      cursor: pointer;
      display: inline;
      line-height: $md-checkbox-size;
      vertical-align: top;
      clear: both;
      padding-left: 1px;
      font-size: 12pt;
      &:not(:empty) {
        padding-left: $md-checkbox-label-padding;
      }

      &:before, &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
      }

      &:before {
        // box
        width: $md-checkbox-size;
        height: $md-checkbox-size;
        background: #fff;
        border: $md-checkbox-border-width solid $md-checkbox-border-color;
        border-radius: $md-checkbox-border-radius;
        cursor: pointer;
        transition: background .3s;
      }

      &:after {
        // checkmark
      }
    }

    input[type="checkbox"] {
      outline: 0;
      visibility: hidden;
      width: $md-checkbox-size;
      margin: 0;
      display: block;
      float: left;
      font-size: inherit;

      &:checked {
         + label:before{
          background: $md-checkbox-checked-color;
          border:none;
        }
        + label:after {

          $md-checkmark-size: $md-checkbox-size - 2 * $md-checkbox-padding;

          transform: translate($md-checkbox-padding, ($md-checkbox-size / 2) - ($md-checkmark-size / 2.6)) rotate(-45deg);
          width: $md-checkmark-size;
          height: $md-checkmark-size / 2;

          border: $md-checkmark-width solid $md-checkmark-color;
          border-top-style: none;
          border-right-style: none;
        }
      }

      &:disabled {
        + label:before{
          border-color: $md-checkbox-border-color-disabled;
        }
        &:checked {
          + label:before{
            background: $md-checkbox-checked-color-disabled;
          }
        }
      }
    }
}

.submitted-container {
    position: relative;
    text-align: center;
    width: 100%;
    padding-top: 5vh;
}

.submitted-text {
    position: absolute;
    width: 100%;
}

.submitted-icon {
    font-size: 52pt;
    color: $ewt-red;
}

.submitted-container h2 {
    margin-top: 20px;
    color: $ewt-red;
}

.submitted-container h5 {
    margin-top: 20px;
}

.temp-xmas-banner {
    text-align: center;
    background-color: #3b2d38;
    color: white;
    padding: 10px;
    margin-bottom: 30px;
}

.temp-xmas-banner h5 {
    margin-bottom: 5px;
}

.temp-xmas-banner p {
    margin: 0px;
}