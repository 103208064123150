// Variable overrides
$body-bg: #fff;

$ewt-red: #642216;

$md-radio-checked-color: #642216;
$md-radio-border-color: rgba(0, 0, 0, 0.54);
$md-radio-border-error-color: red;
$md-radio-size: 30px;
$md-radio-checked-size: 20px; 

$md-checkbox-checked-color: #642216;
$md-checkbox-border-color: rgba(0, 0, 0, 0.54);
$md-checkbox-border-error-color: red;
$md-checkbox-border-color-disabled: rgba(0, 0, 0, 0.26);
$md-checkbox-checked-color-disabled: rgba(0, 0, 0, 0.26);
$md-checkbox-margin: 1em 40px 0 0;
$md-checkbox-size: 1.75em;
$md-checkbox-padding: .25em;
$md-checkbox-border-width: 2px;
$md-checkbox-border-radius: 0.125em;
$md-checkmark-width: 0.25em;
$md-checkmark-color: #fff;
$md-checkbox-label-padding: .75em;